import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Style import
import '../styles/global.scss';

const ErrorPage = ({ location = { state: { message: 'Not defined' } } }) => {
  const message = Object.prototype.hasOwnProperty.call(location, 'state')
    ? location.state.message
    : 'Undefined';

  return (
    <div>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Paper className="intro">
            <div className="Layout">{message}</div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

ErrorPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ErrorPage;
